import {
	Box,
	Button,
	CreateToastFnReturn,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Icon,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Show,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	useMediaQuery,
	useToast,
	VisuallyHidden,
	VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { NextPage } from 'next';
import Head from 'next/head';
import { NextRouter, useRouter } from 'next/router';
import { getSession, signIn, signOut } from 'next-auth/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { BsExclamationTriangle, BsEye, BsEyeSlash } from 'react-icons/bs';
import * as z from 'zod';

import { isLatihan } from '@/lib/constants';
import {
	getFailToastConfigs,
	getWarningToastConfigs,
} from '@/lib/toastConfigs';

import BaseLink from '@/components/links/BaseLink';
import PrimaryButton from '@/components/links/PrimaryButton';

import { checkLMSStatus } from '@/http/auth/checkLMSStatus';

import { UserRole } from '@/types/Role';

const MobileWarningBox = () => {
	return (
		<Stack w="full" spacing={0} p="4" bgColor="red" color="white">
			<Text fontWeight="semibold" fontSize="md">
				EDM tidak mendukung perangkat mobile (smartphone / tablet)
			</Text>
			<Text fontSize="md" fontWeight="semibold" py="6">
				Silakan menggunakan peramban desktop (laptop / komputer) untuk mengisi
				EDM
			</Text>
		</Stack>
	);
};

const MobileWarningModal = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [isMobile] = useMediaQuery('(max-width: 768px)', {
		ssr: true,
		fallback: false,
	});

	React.useEffect(() => {
		if (isMobile) {
			onOpen();
		}
	}, [isMobile, onOpen]);

	return (
		<Modal isOpen={isOpen} onClose={() => onClose()} size="full" isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody py="6">
					<ModalHeader w="full" textAlign="center" textTransform="uppercase">
						<Icon as={BsExclamationTriangle} mr="2" color="yellowgreen" />
						Peringatan
						<Icon as={BsExclamationTriangle} ml="2" color="yellowgreen" />
					</ModalHeader>
					<MobileWarningBox />
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" mr={3} onClick={() => onClose()}>
						Tutup
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

const LoginFormSchema = z.object({
	nik: z.string().min(1, { message: 'NIK harus diisi' }),
	password: z.string().min(1, { message: 'Password harus diisi' }),
});

type LoginFormData = z.infer<typeof LoginFormSchema>;

///according this information:
///https://docs.google.com/presentation/d/1NOS1qd_4NjfIpcjkpIXMDQQcJ1J_HtXr5pIy_VKmESg/edit#slide=id.g1661de01de5_0_19
const checkStatusLMSFunc = async ({
	email,
	toast,
}: {
	email: string;
	toast: CreateToastFnReturn;
}) => {
	try {
		const checkLMSStatusData = await checkLMSStatus({
			email,
		});

		const textRegistrasi =
			checkLMSStatusData?.status_aktivasi?.toString() === '1'
				? 'Sudah Terdaftar'
				: 'Belum Terdaftar';
		const textAktivasi =
			checkLMSStatusData?.status_aktivasi?.toString() === '1'
				? 'Sudah Aktivasi'
				: 'Belum Aktivasi';

		const isLMSnotOK =
			textRegistrasi !== 'Sudah Terdaftar' || textAktivasi !== 'Sudah Aktivasi';

		if (isLMSnotOK) {
			let description = '';

			if (textRegistrasi) {
				description += textRegistrasi;
			}

			if (textAktivasi) {
				if (description) {
					description += ' dan ';
				}

				description += textAktivasi;
			}

			toast(
				getWarningToastConfigs({
					title: 'Tidak Terdaftar di LMS',
					description,
				})
			);

			await signOut({ redirect: false });
			return false;
		}

		return true;
	} catch (error) {
		console.error({ error });
		if (error instanceof AxiosError) {
			toast(
				getWarningToastConfigs({
					title: 'Tidak Terdaftar di LMS',
				})
			);
		} else if (error instanceof Error) {
			toast(
				getFailToastConfigs({
					title: 'Login gagal format response dari LMS tidak dikenali',
					description: error.message,
				})
			);
		}
		await signOut({ redirect: false });
		return false;
	}
};

export { getServerSideProps } from '@/pages/index';

const routeToUserHome = async ({
	router,
	role,
	toast,
	email,
}: {
	router: NextRouter;
	role: UserRole;
	toast: CreateToastFnReturn;
	email: string;
}) => {
	let isAbleToLogin = true;

	switch (role) {
		case UserRole.Pusat:
			return await router.push('/pusat');
		case UserRole.StafMadrasah:
		case UserRole.BendaharaMadrasah: {
			if (isLatihan) {
				isAbleToLogin = await checkStatusLMSFunc({ email, toast });
			}

			if (isAbleToLogin) {
				return await router.push('/madrasah');
			}

			return;
		}
		case UserRole.KepalaMadrasah: {
			if (isLatihan) {
				isAbleToLogin = await checkStatusLMSFunc({ email, toast });
			}

			if (isAbleToLogin) {
				return await router.push('/kepala-madrasah');
			}

			return;
		}
		case UserRole.Provinsi:
			return await router.push('/provinsi');
		case UserRole.KabKota:
			return await router.push('/kabupaten');
		case UserRole.Pengawas:
			return await router.push('/pengawas');
		default:
			toast(
				getFailToastConfigs({
					title: 'Login gagal',
					description: `Role anda tidak didukung di EDM. Role anda: ${role}`,
					duration: 5000,
				})
			);
			signOut({ redirect: false });
			break;
	}
};

const LoginPage: NextPage = (): JSX.Element => {
	const [showPassword, setShowPassword] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<LoginFormData>({
		resolver: zodResolver(LoginFormSchema),
	});

	const router = useRouter();

	const toast = useToast();

	const errorModalDisclosure = useDisclosure();
	const [errorModalMessage, setErrorModalMessage] = React.useState('');

	const onSubmit = handleSubmit(async (data: LoginFormData) => {
		const signInResponse = await signIn('credentials', {
			nik: data.nik,
			password: data.password,
			redirect: false,
		});

		if (signInResponse?.error) {
			let loginErrorMessage =
				'Pastikan NIK dan password benar, silakan coba lagi';
			try {
				const parsedError = JSON.parse(signInResponse.error);

				if (typeof parsedError.error === 'string') {
					loginErrorMessage = parsedError.error;
				}

				if (
					loginErrorMessage.trim()?.toLowerCase()?.includes?.('error system')
				) {
					loginErrorMessage = 'ERKAM System Error';
				}

				if (
					loginErrorMessage
						.trim()
						?.toLowerCase()
						?.includes?.('madrasah not found')
				) {
					const nsm = loginErrorMessage.split(':')[1];
					setErrorModalMessage(
						`Madrasah dengan <b>NSM ${nsm}</b> tidak ditemukan. Silakan perbaiki data anda di ERKAM atau hubungi admin ERKAM`
					);
					errorModalDisclosure.onOpen();
					return;
				}

				console.error(parsedError);
			} catch (error) {
				console.error('Login Gagal');
				console.error({ signInResponse });
				captureException(error, {
					tags: {
						error_type: 'login-failed',
					},
				});
			}

			toast(
				getFailToastConfigs({
					title: 'Login gagal',
					description: loginErrorMessage,
				})
			);
			return;
		}

		const session = await getSession();

		const email = session?.email ?? '';
		const role = session?.defaultUserRole ?? '';

		if (role && email) {
			await routeToUserHome({ router, role, toast, email });
		}
	});

	return (
		<>
			<Modal
				isOpen={errorModalDisclosure.isOpen}
				onClose={errorModalDisclosure.onClose}
				isCentered
				size="lg"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Perhatian</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{errorModalMessage ? (
							<Box
								dangerouslySetInnerHTML={{
									__html: errorModalMessage,
								}}
							/>
						) : (
							<Text>Terjadi kesalahan, silakan coba lagi</Text>
						)}

						<Flex justifyContent="flex-end" mt={4}>
							<Button
								colorScheme="kemenag"
								onClick={errorModalDisclosure.onClose}
							>
								Tutup
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Box display="grid" height="100vh" gridTemplateColumns={['', '45% 55%']}>
				<Head>
					<title>Login | EDM</title>
				</Head>
				<Flex
					h={{ base: 'calc(100vh)', md: 'none', sm: 'none' }}
					display={{ base: 'none', md: 'block' }}
					flexDir="column"
					color="white"
					justifyContent="space-between"
					position="relative"
					backgroundColor="kemenag.500"
					_before={{
						content: `""`,
						bgImage: 'url(/images/meeting-photo-login-page.png)',
						bgSize: 'cover',
						bgPosition: 'center',
						position: 'absolute',
						opacity: 0.2,
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						height: '100%',
						width: '100%',
						objectFit: 'cover',
					}}
				>
					<Flex flexDirection="column" h="full" pt={16}>
						<Stack align="left" zIndex={20} px={16}>
							<BaseLink href="/">
								<VisuallyHidden>Halaman Utama</VisuallyHidden>
								<Image
									src="/logos/madrasah-reform-logo.png"
									alt="madrasah reform logo"
									w="70px"
									h="auto"
								/>
							</BaseLink>
						</Stack>
						<Stack
							align="left"
							h="full"
							justify="center"
							flexGrow={1}
							zIndex={20}
							px={16}
						>
							<Stack fontSize="3xl" fontWeight="bold" spacing={-3}>
								<Text>Mewujudkan Janji Pendidikan</Text>
							</Stack>
							<Text fontSize="1xl">Mencerdaskan Kehidupan Bangsa</Text>
						</Stack>
						<Box bg="green.900" px={16} py={10} zIndex={20}>
							<Text>&copy; Copyright Kementerian Agama RI 2022</Text>
						</Box>
					</Flex>
				</Flex>
				<Flex
					flexDir="column"
					h={{ base: 'calc(100vh)', md: 'none', sm: 'none' }}
					px={['4', '20']}
					py={['', '16']}
				>
					<Flex
						align="center"
						justify="right"
						top="6"
						right="6"
						position="absolute"
					>
						<Text fontSize="sm" mr="4" display={{ base: 'none', md: 'block' }}>
							Belum punya akun E-RKAM ?
						</Text>
						<PrimaryButton
							href="https://erkam.kemenag.go.id/#/passport/register"
							display={{ base: 'none', md: 'block' }}
						>
							Daftar Disini
						</PrimaryButton>
					</Flex>

					<Flex
						flexDirection="column"
						alignItems="stretch"
						justifyContent="center"
						w="full"
						flexGrow={1}
					>
						<Stack spacing={3}>
							<Flex flexDirection="column" alignItems="center">
								<Flex
									flexDirection="column"
									alignItems="center"
									justifyContent="center"
								>
									<Text fontSize="xs" color="gray.600">
										Powered by
									</Text>
									<Flex>
										<Image
											h="40px"
											src="/logos/logo-login-1.png"
											alt="logo madrasah reform"
										/>
									</Flex>
								</Flex>
							</Flex>
							<Heading as="h2" size="xl" textAlign="center">
								Masuk ke EDM
							</Heading>
							<Stack textAlign="center" spacing={-1}>
								<Text fontSize="xl" fontWeight="light">
									Masuk dengan menggunakan akun E-RKAM madrasah anda
								</Text>
							</Stack>
						</Stack>
						<Stack>
							<form onSubmit={onSubmit}>
								<VStack
									align="left"
									spacing={6}
									mt={{ base: '6', md: 'none', sm: 'none' }}
								>
									<FormControl isInvalid={!!errors.nik}>
										<FormLabel htmlFor="nik" fontSize="md">
											NIK
										</FormLabel>
										<Input
											focusBorderColor="kemenag.500"
											placeholder="Masukkan NIK anda..."
											id="nik"
											{...register('nik')}
										/>
										<Text fontSize="xs" color="gray.500">
											NIK yang terdaftar di E-RKAM
										</Text>
										<FormErrorMessage>{errors.nik?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={!!errors.password}>
										<FormLabel htmlFor="password" fontSize="md">
											Password
										</FormLabel>
										<InputGroup>
											<Input
												pr="4.5rem"
												type={showPassword ? 'text' : 'password'}
												focusBorderColor="kemenag.500"
												placeholder="Masukkan password anda..."
												id="password"
												{...register('password')}
											/>
											<InputRightElement width="4.5rem">
												<Button
													h="1.75rem"
													size="sm"
													onClick={() => setShowPassword((prev) => !prev)}
												>
													{showPassword ? (
														<Icon as={BsEyeSlash} />
													) : (
														<Icon as={BsEye} />
													)}
												</Button>
											</InputRightElement>
										</InputGroup>
										<FormErrorMessage>
											{errors.password?.message}
										</FormErrorMessage>
									</FormControl>

									<Button
										w="full"
										type="submit"
										value="Login"
										bg="kemenag.500"
										color="white"
										_hover={{
											bg: 'teal.500',
										}}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Spinner color="white" /> : 'Masuk'}
									</Button>
								</VStack>
							</form>
							<Show below="md">
								<MobileWarningModal />
								<MobileWarningBox />
							</Show>
						</Stack>
					</Flex>
				</Flex>
			</Box>
		</>
	);
};

export default LoginPage;
