import { apiClientEDM } from '@/http/api-client';

import { Response } from '@/types/responses/Response';

export type GetCheckLMSStatusResponse = Response<{
	message?: {
		email?: string;
		status_registrasi?: number;
		status_aktivasi?: number;
	};
}>;

export const checkLMSStatus = async ({ email }: { email: string }) => {
	const params = {
		email,
	};

	const response = await apiClientEDM.get<GetCheckLMSStatusResponse>(
		`data/lms/akun`,
		{
			params,
		}
	);

	return response.data.return.message;
};
